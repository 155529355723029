<template>
  <div id="pub_3d_viewer">
  </div>
</template>

<script>
import __C from '@/primitives/_constant_'
import * as THREE from 'three/build/three.module.js'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader'
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass'
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass'
import { BrightnessContrastShader } from 'three/examples/jsm/shaders/BrightnessContrastShader'
import Loading from '@/mixins/loading.mixin'

export default {
  mixins: [
    Loading
  ],
  data: () => ({
    tokenCheckService: null,

    composer: null,
    scene: null,
    camera: null,
    renderer: null,
    mixer: null,
    controls: null,

    dirLight: null,

    loader:  null,
    
    speed :2,
    delta :0,

    width: 1800,
    height: 1100
  }),
  watch: {
    '$route.params': {
      handler() {
        this.callComponent()
      },
      deep: true
    }
  },
  mounted() {
    this.loading = false
    this.init()

    window.addEventListener('resize', function() {
      if(this.renderer) this.renderer.setSize(this.width, this.height)
    })
  },
  methods: {
    init() {
      this.clock = new THREE.Clock()

      let fbxName = '21-Z-244010'
      // let fbxName = 'ME04DB'
      
      let container = document.getElementById('pub_3d_viewer')

      this.camera = new THREE.PerspectiveCamera( 45, this.width / this.height, 1, 2000 );
      this.camera.position.set(12, 4, 9);

      this.scene = new THREE.Scene()
      this.scene.background = new THREE.Color(0xffffff)
      this.scene.add( new THREE.AmbientLight( 0x111122 ) )

      let light = new THREE.HemisphereLight(0xc0c0c0, 0x757575)
      light.position.set( 5, 10, 7.5 );
      this.scene.add(light)

      let spotLight = new THREE.SpotLight( 0xffffff, .35 );
      spotLight.position.set( 2.7, 35, 25 );
      spotLight.angle = Math.PI / 4;
      spotLight.penumbra = 0.05;
      spotLight.decay = 2;
      spotLight.distance = 100;

      spotLight.castShadow = true;
      spotLight.shadow.mapSize.width = 1024;
      spotLight.shadow.mapSize.height = 1024;
      spotLight.shadow.camera.near = 10;
      spotLight.shadow.camera.far = 200;
      spotLight.shadowDarkness = 1
      this.scene.add( spotLight )

      // let mesh = new THREE.Mesh(
      //   new THREE.PlaneBufferGeometry( 100, 100, 1, 1 ),
      //   new THREE.MeshPhongMaterial( { color: 0xffffff, depthWrite: true } ) 
      // )
      // mesh.rotation.x = - Math.PI / 2
      // mesh.receiveShadow = true
      // this.scene.add( mesh )

      this.renderer = new THREE.WebGLRenderer({ alpha: true, antialias: true })
			this.renderer.setPixelRatio( window.devicePixelRatio )
			this.renderer.setSize(this.width, this.height)
      this.renderer.shadowMap.enabled = true
      // this.renderer.shadowMap.type = THREE.BasicShadowMap
      this.renderer.shadowMap.type = THREE.PCFSoftShadowMap
      this.renderer.outputEncoding = THREE.sRGBEncoding
      this.renderer.gammaOutput = true
      container.appendChild( this.renderer.domElement )

      let brightness = new ShaderPass(BrightnessContrastShader)
      brightness.uniforms.brightness.value = 0.1
      brightness.uniforms.contrast.value = 0.01
      brightness.enabled = true

      this.composer = new EffectComposer(this.renderer)
      this.composer.addPass(new RenderPass(this.scene, this.camera))
      this.composer.addPass(brightness)

      this.controls = new OrbitControls( this.camera, this.renderer.domElement )
			this.controls.target.set( 0, 0, 0 )
			this.controls.enablePan = true
      this.controls.enableDamping = true
      // this.controls.minDistance = 7;
      // this.controls.maxDistance = 500;
			this.controls.update()

      let maxy_ = 1
      this.loader = new FBXLoader()
			this.loader.load( 
        `${__C.HOST_NAME_RESOURCE}/Trion/3DModels/Equipments/${fbxName}.fbx`, 
        (object_) => {
          let boundingBox = new THREE.Box3().setFromObject(object_)
          // let center = boundingBox.getCenter( new THREE.Vector3() )
          let size = boundingBox.size()
          let defaultSize = {
            x: 7.5,
            y: 7.5,
            z: 7.5,
          }
          let scale = 1.

          let sValues = Object.values(size)
          let idx = sValues.indexOf(Math.max(...sValues))

          if(idx === 0) scale = defaultSize.x / size.x
          else if(idx == 1) scale = defaultSize.y / size.y
          else scale = defaultSize.z / size.z

          object_.castShadow = true
          object_.receiveShadow = true
          object_.position.set(0, 0, 0)
          object_.scale.set(object_.scale.x * scale, object_.scale.y * scale, object_.scale.z * scale)

          object_.traverse(function (child) {
            if (child instanceof THREE.Mesh) {
              child.castShadow = true
              child.receiveShadow = true
            }
          })

          this.scene.add(object_)
          this.animate()
        },
        // called while loading is progressing
        function ( xhr ) {
          // console.log( ( xhr.loaded / xhr.total * 100 ) + '% loaded' )
        },
        // called when loading has errors
        function ( error ) {
          console.log(error)
        } 
      )
    },
    animate() {
      requestAnimationFrame(this.animate)
      this.controls.update()
      // this.delta = this.clock.getDelta()
      // this.model.position.x += this.speed * this.delta
      // this.model.rotation.y += this.clock.getDelta() * 1
      // this.model.rotation.y += this.clock.getDelta() * Math.PI / 180
      // this.model.rotation.x += this.clock.getDelta() * 45 * Math.PI / 180
      this.renderer.render(this.scene, this.camera)
      this.composer.render()
    },
    callComponent() {
      // try{
      //   let token = this.$route.params.token
      //   let code = this.$route.params.code // fbx file name
      //   let request_ = JSON.parse(this.$route.params.action)

      //   let data = `{"sn":866,"id":"kto123","password":"","userName":"TaeOh Kim","email":"magicbox10@naver.com","position":"Developer","office":"JGS INC.","department":"Home office","officePhone":"","cellPhone":"","userPermit":"SYS_ADMIN","groupPermit":"","approval":"APPROVED","regDate":"2020-06-25T08:37:48.18Z","hit":"0","comment":"","token":"${token}","permitName":"","approvalName":""}`
      //   localStorage.setItem(__C.LOCAL_STORAGE_NAME.ACCOUNT, data)
      //   localStorage.setItem(__C.LOCAL_STORAGE_NAME.TOKEN, token)
      //   localStorage.setItem(__C.LOCAL_STORAGE_NAME.TIMEOUT, __M().format('YYYY-MM-DD HH:mm:ss'))

      //   this.tokenCheckService.tokenCheck(token).then(res => {
      //     if (res) {
      //       this.exComponent = request_.component
      //       this.exFilters = request_.filter
      //       this.exTarget = request_.target
      //       setTimeout(() => { if(this.$refs.target.run) this.$refs.target.run() })
      //       setTimeout(() => {
      //         this.signout()
      //       }, 60000)            
      //     }else {
      //       this.signout()
      //       // this.$router.push('/InvalidAccess')
      //     }
      //   })
      //   // this.exComponent = request_.component
      //   // this.exFilters = request_.filter
      //   // this.exTarget = request_.target
      //   // setTimeout(() => { if(this.$refs.target.run) this.$refs.target.run() })
      // } catch(e) {
      //   console.error(e)
      // }
    }
  }
}
</script>

<style lang="stylus" scoped>
#pub_3d_viewer
  background-color #fff
  overflow hidden
  margin -1
  
</style>
